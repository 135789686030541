<template>
  <div class="sys-index">
   <el-carousel trigger="click" :height="bannerHeight">
      <el-carousel-item v-for="item in 4" :key="item">
      <div class="index-banner" :style="{height:bannerHeight}"></div>
      </el-carousel-item>
    </el-carousel>
    <div style="position: relative;
    top: -60px;
    z-index: 10;
    background: #f9f9f9;">
      <div style="display:flex;">
        <div class="company-js">
          <div class="title">公司简介</div>
          <div class="tips">高考志愿一点通</div>
          <div class="tips">助力莘莘学子大学圆梦</div>
          <div class="content">“高考志愿一点通”项目，是山东宇嘉雪教育科技有限公司依托阿里巴巴服务平台，联合创新型上市科技集团，并聘请高校知名心理学教授担任顾问自主研发的精准化、人性化、简单化高考志愿填报方案制定大型服务项目。公司拥有清华、北大、浙大、南大等众多知名高校的优秀人才，基于12年传统报考经验，结合2020年新高考政策变化，充分研究北京、天津、上海、山东、浙江、海南等省份新高考录取策略方法和相关数据，应用云计算、算法分析、互联网大数据等前沿信息数据采集分析方法，生成科学、精准、个性化的志愿填报方案。</div>
        </div>
        <div class="company-pic"></div>
      </div>
      </div>
      <div class="why">
          <template v-for="items in 4">
          <div class="why-div" :key="items" >
            <div :class="`imgs${items}`">
              <!-- <img :src="require(`../../assets/images/00${items}.jpg`)" alt=""> -->
            </div>
            <!-- <div class="title">{{items.name}}</div> -->
          </div>
        </template>
      </div>
      <div class="tip">为什么选择 高考志愿一点通</div>
      <div class="index-bottom">
          <template v-for="items in 3">
          <div class="index-bottom-div" :key="items" >
            <div :class="`imgs${items}`">
            </div>
          </div>
        </template>
      </div>
      <!-- <div class="hzjg"></div> -->
      <div class="bottom-content">
        <template v-for="(items,index) in bottomContent">
          <div class="content-div" :key="index" @click="jump(items.path)">
            <div class="imgs">
              <img :src="require(`../../assets/images/${items.icon}`)" alt="">
            </div>
            <div class="title">{{items.name}}</div>
          </div>
        </template>
        <div style="margin-right:20px;">
           <el-select v-model="value" @change="changeOptions" placeholder="请选择">
    <el-option
      v-for="item in options"
      :key="item.value"
      :label="item.label"
      :value="item.value">
    </el-option>
  </el-select>
        </div>
      </div>
      <div class="foot-police">
      <img src="../../assets/images/police.png" />
      <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank"
        >鲁ICP备2021009162号&nbsp;&nbsp;工业和信息化部备案管理系统网站</a
      >
    </div>
  </div>
</template>

<script>

export default {
  name: 'Sysindex',
  computed: {
    bannerHeight() {
      const { clientWidth } = document.body;
      // eslint-disable-next-line no-mixed-operators
      const height = clientWidth * 380 / 1920;
      return height + 'px';
    },
  },
  data() {
    return {
      options: [{
          value: 'https://www.baidu.com',
          label: '--各省市招考机构--'
        },{
          value: 'https://www.bjeea.cn/',
          label: '北京教育考试院'
        },{
          value: 'http://www.zhaokao.net/',
          label: '天津市教育考试院'
        },{
          value: 'http://www.hebeea.edu.cn/',
          label: '河北省教育考试院'
        },{
          value: 'http://www.sxkszx.cn/',
          label: '山西省招生考试管理中心'
        },{
          value: 'https://www.nm.zsks.cn/',
          label: '内蒙古教育招生考试中心'
        },{
          value: 'https://www.lnzsks.com/',
          label: '辽宁省招生考试办公室'
        },{
          value: 'http://www.jleea.edu.cn/',
          label: '吉林省教育招生考试院'
        },{
          value: 'https://www.lzk.hl.cn/lzk/',
          label: '黑龙江省招生考试委员会'
        },{
          value: 'http://www.shmeea.edu.cn/page/index.html',
          label: '上海教育考试院'
        },{
          value: 'http://www.jseea.cn/',
          label: '江苏省教育考试院'
        },{
          value: 'https://www.zjks.net/',
          label: '浙江省教育考试院'
        },{
          value: 'https://www.ahzsks.cn/',
          label: '安徽省教育招生考试院'
        },{
          value: 'https://www.eeafj.cn/',
          label: '福建省教育考试院'
        },{
          value: 'http://www.jxeea.cn/',
          label: '江西省教育考试院'
        },{
          value: 'http://www.heao.gov.cn/',
          label: '河南省招生办公室'
        },{
          value: 'http://www.hbea.edu.cn/',
          label: '湖北省教育考试院'
        },{
          value: 'https://www.hneeb.cn/',
          label: '湖南省教育考试院'
        },{
          value: 'http://eea.gd.gov.cn/',
          label: '广东省教育考试院'
        },{
          value: 'http://www.gxeea.cn/',
          label: '广西招生考试院'
        },{
          value: 'http://ea.hainan.gov.cn/',
          label: '海南省考试局'
        },{
          value: 'https://www.cqksy.cn/',
          label: '重庆市教育考试院'
        },{
          value: 'http://www.xn--wbsy6niugtwtqjk83ai53cnjt.cn/',
          label: '四川省教育考试院'
        },{
          value: 'http://www.eaagz.org.cn/',
          label: '贵州省招生考试中心'
        },{
          value: 'https://www.ynzs.cn/',
          label: '云南省招生考试院'
        },{
          value: 'http://www.xzzsks.com.cn/',
          label: '西藏教育考试院'
        },{
          value: 'http://www.sxsksglzx.com/',
          label: '陕西省考试管理中心'
        },{
          value: 'https://www.ganseea.cn/',
          label: '甘肃教育考试院'
        },{
          value: 'http://www.qhjyks.com/',
          label: '青海省教育招生考试院'
        },{
          value: 'https://www.nxjyks.cn/',
          label: '宁夏教育考试院'
        },{
          value: 'http://www.xjzk.gov.cn/',
          label: '新疆维吾尔自治区教育考试院'
        }],
        value: 'https://www.baidu.com',
      bottomContent: [
        {
          icon: 'icon-ft4.png',
          name: '中国人民共和国教育部',
          path: 'http://www.moe.gov.cn/'
        },
        {
          icon: 'icon-ft4.png',
          name: '山东省人民政府',
          path: 'http://www.shandong.gov.cn/'
        },
        {
          icon: 'icon-ft3.png',
          name: '中国教育考试网',
          path: 'http://www.neea.edu.cn/'
        },
        {
          icon: 'icon-ft2.png',
          name: '中国高等教育学生信息网',
          path: 'https://www.chsi.com.cn/'
        },
        {
          icon: 'yggkp.png',
          name: '阳光高考平台',
          path: 'https://gaokao.chsi.com.cn/'
        },
        {
          icon: 'icon-ft1.png',
          name: '山东省教育厅',
          path: 'http://edu.shandong.gov.cn/'
        }
      ]
    }
  },
  methods: {
    changeOptions(value) {
      window.open(value)
    },
    jump(path) {
      window.open(path)
    } 
  }
}
</script>

<style lang="scss" scoped>
.why {
  height: 600px;
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  top:-20px;
//     background: url("../../assets/images/图片1.jpg") no-repeat;
//   background-size: 100% 100%;
   .why-div{
      width: 23%;
      height: 600px;
      margin: 0 1%;
      .imgs1{
          width: 100%;
          height: 100%;
            background: url("../../assets/images/001.jpg") no-repeat;
  background-size: 100% 100%;
      }
       .imgs2{
          width: 100%;
          height: 100%;
            background: url("../../assets/images/002.jpg") no-repeat;
  background-size: 100% 100%;
      }
       .imgs3{
          width: 100%;
          height: 100%;
            background: url("../../assets/images/003.jpg") no-repeat;
  background-size: 100% 100%;
      }
       .imgs4{
          width: 100%;
          height: 100%;
            background: url("../../assets/images/004.jpg") no-repeat;
  background-size: 100% 100%;
      }
  }
}
.company-pic {
    width:598px;
    height:448px;
    margin-left: 15px;
//   border:1px solid red;
   background: url("../../assets/images/index-banner.png") no-repeat;
  background-size: 100% 100%;
}
.bottom-content {
  width: 100%;
  margin: 0 auto 50px auto;
    display: flex;
  // border: 1px solid red;
  justify-content: center;
  align-items: center;
  background-color: #2c80de;
  // 2668b2
  .content-div {
    width: 16%;
    display: flex;
     justify-content: center;
  align-items: center;
  cursor: pointer;
  color: white;
  &:hover {
  background-color: #2668b2;

  }
  }
}
.index-banner {
//   height: 400px;
// height: 2300px;
  // border:1px solid red;
  position: relative;
  top:-60px;
//   margin-bottom: 50px;
  background: url("../../assets/images/gsjj.jpg") no-repeat;
  background-size: 100% 100%;
}
.index-bottom {
  height: 300px;
  // border:1px solid red;
  margin: 0 auto 50px auto;
    width: 93%;
  display: flex;
  justify-content: center;
  .index-bottom-div{
      width: 31%;
      height: 300px;
      margin: 0 1.3%;
      .imgs1{
          width: 100%;
          height: 100%;
            background: url("../../assets/images/hh1.png") no-repeat;
  background-size: 100% 100%;
      }
       .imgs2{
          width: 100%;
          height: 100%;
            background: url("../../assets/images/hh2.png") no-repeat;
  background-size: 100% 100%;
      }
       .imgs3{
          width: 100%;
          height: 100%;
            background: url("../../assets/images/hh3.png") no-repeat;
  background-size: 100% 100%;
      }
  }
//   background: url("../../assets/images/hh.jpg") no-repeat;
//   background-size: 100% 100%;

}
.company-js {
  width: 50%;
  padding-left: 100px;
  padding-top: 20px;
    padding-right: 45px;
  .title {
    width: 120px;
    color: #216cad;
    font-weight: bold;
    font-size: 26px;
    padding-bottom: 10px;
    border-bottom: 2px solid #216cad;
  }
  .tips {
    margin-top: 10px;
    text-align: left;
    font-weight: bold;
    font-size: 26px;
  }
  .content {
    margin: 20px 0 40px 0;
    letter-spacing: 3px;
    text-align: left;

  }
}
  .tip {
    font-weight: bold;
    font-size: 26px;
    // margin-bottom:-50px;
    margin: 0px 0px 20px 0;
  }
  .hzjg {
      height: 400px;
  // border:1px solid red;
  margin-bottom: 50px;
  background: url("../../assets/images/hzjg.png") no-repeat;
  background-size: 100% 100%;
  }
  .foot-police {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px !important;
  img {
    width: 14px;
    height: 14px;
  }
  a {
    text-decoration: none;
    color: #666;
  }
  a:hover {
    text-decoration: underline;
    text-decoration-color: #666;
  }
}
</style>